// interfaces
import React, { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// components
import { Container, Box, makeStyles, Fab } from '@material-ui/core';
import { Header } from './Header';
import { ScrollTop } from './ScrollTop';
import { KeyboardArrowUp } from '@material-ui/icons';

const useStyles = makeStyles({
  main: {
    margin: '15px 0 100px 0',
  },
});

export const Layout: FC = ({ children }) => {
  const cls = useStyles();

  return (
    <>
      <Router>
        <Header />
        <Box className={cls.main}>
          <Container maxWidth="md">
            <Box>{children}</Box>
          </Container>
        </Box>
      </Router>
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </>
  );
};

export type Rating = {
  availability?: number;
  common?: number;
  currency_rate?: number;
  quality?: number;
  safety?: number;
};

export type Review = {
  booking_pretty_code?: string;
  changed?: string;
  complaints?: number | 0;
  images?: string[];
  likes?: number | 0;
  moderated?: string;
  previews: string[];
  profile_id: string;
  rating: Rating;
  response?: {
    text?: string;
    added?: string;
  };
  status?: `${Status}`;
  tags: Array<`${Tags}`>;
  target_id: string;
  text?: string;
  type: string;
  _links: any;
  _created: string;
  _id: string;
};

export enum Status {
  Active = 'active',
  Canceled = 'canceled',
  Complaint = 'complaint',
  Created = 'created',
  Deleted = 'deleted',
}

export enum Tags {
  PosNoErrors = 'pos_no_errors',
  PosNoFraud = 'pos_no_fraud',
  PosAgreements = 'pos_agreements',
  PosRequiredAmount = 'pos_required_amount',
  PosFound = 'pos_found',
  PosSafe = 'pos_safe',
  PosVisitOnTime = 'pos_visit_on_time',
  PosGoodCommunication = 'pos_good_communication',
  NegErrors = 'neg_errors',
  NegFraud = 'neg_fraud',
  NegNoAgreements = 'neg_no_agreements',
  NegNoRequiredAmount = 'neg_no_required_amount',
  NegNotFound = 'neg_not_found',
  NegUnsafe = 'neg_unsafe',
  NegCommunication = 'neg_bad_communication',
  NegVisitWrongTime = 'neg_visit_wrong_time',
}

export type Links = {
  parent: {
    href: string;
    title: string;
  };
  self: {
    href: string;
    title: string;
  };
};

export type Meta = {
  max_results: number;
  page: number;
  total: number;
};

export enum RatingType {
  All = 'all',
  Negative = 'negative',
  Positive = 'positive',
}

export interface ReviewResponse {
  _items: Review[];
  _links: Links;
  _meta: Meta;
}

import { render } from 'react-dom';
import { App } from './components/App';
import { Layout } from './components/Layout';
import { CssBaseline } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

export const queryClient = new QueryClient();

render(
  <>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <Layout>
        <App />
      </Layout>

      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </>,
  document.getElementById('root')
);
